import React, { Component , Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp, FiX, FiMenu, FiHeadphones, FiMail, FiMapPin} from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Slider from "react-slick";
import { slickDot , portfolioSlick2 } from "../page-demo/script";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Akkök Mobilya | En İyisi',
        description: 'Since 1958',
        buttonLink: '/contact'
    }
]
const Portfolio_image = <img src="/assets/images/portfolio/interior/portfolio-8.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/interior/portfolio-9.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/interior/portfolio-10.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/interior/portfolio-11.jpg" alt="React Creative Agency" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        title: 'Mutfak',
        description: ''
    },
    {
        image: Portfolio_image2,
        title: 'Villa',
        description: ''
    },
    {
        image: Portfolio_image3,
        title: 'Ev',
        description: ''
    },
    {
        image: Portfolio_image4,
        title: 'Ofis',
        description: ''
    },
]

const PortfolioList2 = [
    {
        image: Portfolio_image,
        category: 'Design',
        title: 'Landcape Design',
        description: 'Lorem ipsum dolor sit amet, conse. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image2,
        category: 'Design',
        title: 'Landcape Design',
        description: 'Lorem ipsum dolor sit amet, conse. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image3,
        category: 'Interior',
        title: 'Home Interior',
        description: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image4,
        category: 'Interior',
        title: 'Home Interior',
        description: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image2,
        category: 'Lighting',
        title: 'Bedroom Lighting',
        description: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, conse.'
    },
    {
        image: Portfolio_image3,
        category: 'Lighting',
        title: 'Bedroom Lighting',
        description: 'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, conse.'
    }
]


const ServiceListOne = [
    {
        icon: "roleve.png",
        title: 'Röleve',
        description: 'Mevcut yapınızı ölçerek; plan, kesit, görünüş ve detay olarak çizimlerini yapıyoruz.'
    },
    {
        icon: "tasarim.png",
        title: 'Tasarım',
        description: 'Dilerseniz hayalinizdeki ürünleri, dilerseniz ünlü tasarımcılardan esinlenerek oluşturacağımız ürünleri üretiyoruz.'
    },
    {
        icon: "sunum.png",
        title: 'Sunum',
        description: 'Oluşturacağımız ürünleri sizlere en ayrıntılı bir biçimde sunuyoruz ve önerilerinizi alıyoruz.'
    },
    {
        icon: "teklif.png",
        title: 'Teklif',
        description: 'Proje ve satış aşamadında hızlı sunum ve teklif hazırlama konusunda rakipsiz bir çözüm sunuyoruz.'
    },
    {
        icon: "uretim.png",
        title: 'Üretim',
        description: 'En son teknolojileri kullanarak sağlam, güvenilir, kullanımı kolay olan ürünlerimizi üretiyoruz!'
    },
    {
        icon: "montaj.png",
        title: 'Montaj',
        description: 'Üretilen ürünleri titizlikle montajını gerçekleştiriyoruz.'
    },
]

class InteriorLanding extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader () {}
    render(){
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return(
            <Fragment>
                <Helmet pageTitle="Akkök Mobilya" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['anasayfa','uretim','hizmetler','hizmetal','akkok','portfolyo','iletisim']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#anasayfa">Anasayfa</a></li>
                                    <li><a href="#uretim">Üretim</a></li>
                                    <li><a href="#hizmetler">Hizmetler</a></li>
                                    <li><a href="#hizmetal">Hizmet Al</a></li>
                                    <li><a href="#akkok">Hakkında</a></li>
                                    <li><a href="#portfolyo">Portfolyo</a></li>
                                    <li><a href="#iletisim">İletişim</a></li>

                                </Scrollspy>
                            </nav>


                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation im_modern_slider" id="anasayfa">
                    <div className="bg_image bg_image--29" data-black-overlay="5">
                        {SlideList.map((value , index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner pt_sm--40 ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="btn-default btn-large" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="uretim">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-8">
                                <div className="feature-area">
                                    <span className="subtitle theme-gradient">Ahşap'ta onlarca yıllık Sanat...</span>
                                    <h3 className="title mt--20 fontWeight500 lineheight--1-8">Akkök Mobilya;<br/>Ev, Villa, Ofis, Mutfak vb. özel mobilya ihtiyacı duyduğunuz tüm mekanlarda üst düzey kaliteli mobilya üretimi yapmaktadır. </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={i}>
                                    <a href="/">
                                        <div className="service service__style--1">
                                            <div className="icon">
                                                <img src={`/assets/images/icons/${val.icon}`} alt="Service Icon"/>
                                            </div>
                                            <div className="content mt--20">
                                                <h4 className="title">{val.title}</h4>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */}
                {/* Start Portfolio Area */}
                <div className="portfolio-area pb--120 bg_color--1" id="hizmetler">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-6 col-md-6 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="/">
                                                    {value.image}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="/">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title"><Link to="/">{value.title}</Link></h4>
                                                </div>
                                                <div className="portfolio_hover">
                                                    <p>{value.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="transparent_link" to="/"></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Call To Action Area  */}
                <div className="call-to-action-wrapper call-to-action callto-action-style-2 text-white-wrapper" id="hizmetal">
                    <div className="bg_image bg_image--30 ptb--200 ptb_lg--80 ptb_md--80 ptb_sm--80" data-black-overlay="5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner text-center">
                                        <h2>Yeniliklere Hazır Mısın?</h2>
                                        <p>Ev & İş yeriniz için en iyi tercih</p>
                                        <a className="btn-default btn-large btn-hover-white mt--50 mt_sm--30 mt_md--30" href="/contact" style={{textTransform:'capitalize'}}><span>İletişim</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Call To Action Area  */}

                {/* Start CounterUp Area */}
                <div style={{ borderTop: "10px solid #fff ", marginLeft: 20, marginRight: 20 }}></div>
                {/* End CounterUp Area */}

                {/* Start Content Box  */}
                <div className="rn-content-box-area rn-content-box-style--1 pb--120 bg_color--1" id="akkok">
                    <div className="row row--0 align-items-center">
                        <div className="col-lg-12 col-xl-6">
                            <div className="thumbnail">
                                <img src="/assets/images/featured/featured-01.jpg" alt="Featured Images"/>
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-6 mt_lg--50 mt_md--30 mt_sm--30">
                            <div className="content">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">Neden Akkök?</span>
                                        <h2 className="title">Etik Değerlerimiz</h2>
                                        <p className="description">Akkök Mobilya olarak sektöre damgamızı vurmanın haklı gururunu üzerimizde taşımaktayız. Dürüst çalışmayı kendisine ilke edinmil olan firmamız, toplam kalite anlayışına sadık kalarak ve konusunda sürekli araştırma, geliştirme, kalite ve üretim standartlarına uyarak üretimde seçkin bir isime sahip olmuştur.</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Dürüst & İlkeli Çalışma</h5>
                                                <p>Uzun yıllardır başarımızın temel kaynağı dürüst ve ilkeli çalışma prensipimizdir.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Müşteri Memnuniyeti</h5>
                                                <p>En ideal tasarımı ve ürünleri oluşturmakla kalmıypr, satış öncesi ve sonrası destekle sürekli müşteri memnuniyeti sağlıyoruz.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Malzeme Kalitesi</h5>
                                                <p>Ürünlerimizin tamamında 1.sınıf yüksek yoğunluklu malzeme kullanıyoruz. Tüm aksesuar ekipmanda 1.sınıf kalite tercih ediyoruz.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h5 className="title">Sunum</h5>
                                                <p>Son teknolojiler kullanılarak çizilen ürünlerinizi görselleri ile size sunarak birebir imalatını gerçekleştiriyoruz.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {/* End Content Box  */}

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5" id="iletisim">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">İletişim Numaramız</h4>
                                        <p><a href="tel:+90 352 245 2930">0352 245 29 30</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Bize Yazın</h4>
                                        <p><a href="mailto:admin@gmail.com">info@akkokmobilya.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title"><a href="https://g.page/akkokmobilya?share">Konum</a></h4>
                                        <p><a href="https://g.page/akkokmobilya?share">Keresteciler Sitesi, Kocasinan Blv. No: 381 38010 Kocasinan / Kayseri </a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}
                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default InteriorLanding;